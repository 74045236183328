import { domainAuthenticationMap, proxyUrl } from "./config";
import { getJWT } from "./gProxy";
import { resolveDomain } from "./utils/resolveDomain";

export const originalFetch = window.fetch;

export const fetch: typeof originalFetch = async (input, init) => {
  // create normalized request object to inspect whether the request needs to
  // be routed through Gateway
  const request = input instanceof Request ? input : new Request(input, init);

  // run relative requests "as is"
  if (!request.url || request.url.indexOf("//") === -1) {
    return execFetch(request);
  }

  // ... otherwise determine if we need to perform automatic authentication on
  // request by determining the domain
  const requestDomain = resolveDomain(request.url);
  const authMethod = domainAuthenticationMap.get(requestDomain);

  if (!authMethod && !request.headers.get("X-Proxy")) {
    // if no authentication is required, then simply run the request directly
    // to the intended source
    return execFetch(request);
  }

  // ... otherwise transform the request
  request.headers.set("X-Security-Method", authMethod || "IP");
  request.headers.set("X-Access-Token", await getJWT());

  const url = `${proxyUrl}proxy/?url=${encodeURIComponent(
    request.url
  )}&noCache=${Date.now()}`;

  return execFetch(await changeRequestUrl(url, request));
};

const execFetch: typeof originalFetch = async (request) => {
  return originalFetch.call(window, request);
};

async function changeRequestUrl(
  url: string,
  request: Request
): Promise<Request> {
  return new Request(url, {
    body:
      request.method !== "GET" && request.method !== "HEAD"
        ? await request.text()
        : undefined,
    cache: request.cache,
    credentials: request.credentials,
    headers: request.headers,
    integrity: request.integrity,
    keepalive: request.keepalive,
    method: request.method,
    mode: request.mode,
    redirect: request.redirect,
    referrer: request.referrer,
    referrerPolicy: request.referrerPolicy,
    signal: request.signal,
  });
}
